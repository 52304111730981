@import url(https://fonts.googleapis.com/css?family=Dancing+Script|IBM+Plex+Serif|Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css?family=Charmonman|Lobster+Two|Volkhov&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Serif&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  /* background-color: #111111; */
}

html {
  font-family: 'Roboto Slab', serif;
  height: 100%;
}

.MuiTypography-body1 {
  line-height: 1.25;
}

body {
  background-color: #fff;
}

h1 {
  padding: none;
  margin: 0;
}
.logo-pic {
  height: 40px;
  bottom: 0;
}

.precision {
  font-family: 'PT Serif', serif;
}

.about-info {
  font-family: 'Roboto Slab', serif;
  padding: 3%;
  width: 50%;
}

.main-bullets {
  background-color: #fff;
  font-size: 40px;
  color: #010247;
  justify-content: space-between;
  font-weight: 900;
  padding: 4%;
  margin-top: -3%;
}

.flex-contact {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  margin-top: 4%;
  justify-content: space-evenly;
}

.App-header {
  background-color: #111111;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.contact-pic {
  height: 35vh;
}

.navBtn {
  cursor: pointer;
  background-color: #111111;
  border: none;
  color: white;
  padding: 15px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.sessionsMain {
  display: flex;
  flex-wrap: wrap;
}

.tester {
  max-width: 50vw;
}

.navBtn:active {
  border: none;
}

.precisionMain {
  width: 100vw;
  margin-top: -8%;
}

.mainContainer {
  background-color: white;
  height: 100vh;
}

.machine {
  width: 70%;
}

.navBtn:hover {
  background-color: #fff;
  color: #111111;
}
.number {
  padding: 12px 10px;
  justify-items: center;
}

.hr {
  width: 95%;
}

h2 {
  margin: 1%;
}

.MuiAppBar-colorPrimary {
  background-color: #010247;
}

.service-name {
  cursor: pointer;
  margin: 0;
  padding: 2%;
}

.service-name:hover {
  background-color: #fff;
  background-blend-mode: color-burn;
  color: black;
}
.flex-list {
  display: flex;
  color: #fff;
  justify-content: space-evenly;
}

.MuiBox-root-112 {
  padding: 0;
}

.about-pic {
  max-width: 90%;
  margin: 4%;
}

.flex-section {
  display: flex;
  height: 75vh;
  width: 100%;
}

.mailTo {
  color: #fff;
}

.flex-pic {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.tank {
  height: 70vh;
}

.pic-right {
  width: 50%;
}

.MuiTab-wrapper {
  font-size: 1.8rem;
  padding-left: 9%;
  padding-right: 9%;
}

.testing {
  width: 43vw;
}

.footer {
  background-color: #010247;
  height: 1000%;
  color: #fff;
  /* bottom: 0; */
  width: 100%;
  position: relative;
}

.contact-email {
  color: #010247;
}

.flex-section-contact {
  display: flex;
  flex-wrap: wrap;
}
.contact-info {
  margin: 5%;
}

.footer-contact {
  margin: 2%;
}

.footer-info {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 20%;
  font-weight: bold;
}

.MuiTab-wrapper:hover {
  font-size: 1.8rem;
  background-color: #fff;
  color: black;
  opacity: 0.8;
  padding-right: 9%;
}

p {
  margin: 3%;
}

.services {
  font-family: 'Roboto Slab', serif;
  background-color: #fff;
  position: static;
  width: 50%;
}

.MuiTabs-flexContainer {
  margin-top: 1%;
  flex-wrap: wrap;
}

.title {
  text-align: center;
}
.MuiSvgIcon-root {
  margin-bottom: -5px;
  padding-right: 5px;
}
.topNav {
  background-color: #111111;
  width: 100vw;
  color: white;
  display: flex;
  padding: 0;
  position: -webkit-sticky;
  position: sticky;
  opacity: 0.8;
  justify-content: space-between;
}

.App-link {
  color: #61dafb;
}

.logo {
  width: 65vw;
}

.about-pic-section {
  width: 50%;
}

.home-div {
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .precisionMain {
    margin-top: 3%;
  }

  .home-div {
    margin-top: 10vh;
    margin-bottom: 10vh;
  }

  .logo {
    width: 90%;
  }

  .title {
    text-align: center;
    font-weight: bold;
  }

  .footer-info {
    margin-top: 1%;
  }

  .topNav {
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 2%;
  }

  .flex-section {
    flex-wrap: wrap;
    height: 100%;
  }

  .about-info {
    width: 100%;
  }

  .tank {
    width: 90vw;
  }

  .about-pic {
    width: 100%;
  }

  .about-pic-section {
    width: 100%;
  }

  .MuiTabs-flexContainer {
    flex-wrap: wrap !important;
    justify-content: center;
  }

  .services {
    width: 100%;
    margin-bottom: 5%;
  }

  .MuiTab-wrapper {
    font-size: 1rem !important;
    font-weight: bold !important;
  }

  .MuiBox-root-112 {
    padding: 10px !important;
  }

  .hr {
    width: 87vw;
  }

  .testing {
    width: 90vw;
  }
}

